import { render, staticRenderFns } from "./PartitionOrderForm.vue?vue&type=template&id=cd73e27e&scoped=true"
import script from "./PartitionOrderForm.vue?vue&type=script&lang=js"
export * from "./PartitionOrderForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd73e27e",
  null
  
)

export default component.exports