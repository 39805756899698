<template>
  <div>
    <div class="d-flex ma-2 flex-wrap">
      <div class="pa-2">
        <v-btn-toggle v-model="date" :mandatory="!!date" class="btn-group" color="secondary" dense rounded>
          <v-btn v-for="item in dateList" :key="item.value" :value="item.value">
            {{ datePeriodLabel(item) }}
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="pa-2">
        <v-menu
          ref="menu"
          v-model="menuDate"
          :close-on-content-click="false"
          min-width="auto"
          offset-y
          transition="scale-transition"
        >
          <template #activator="{ on, attrs }">
            <v-btn class="filter-btn" color="secondary lighten-2" outlined v-bind="attrs" v-on="on">
              <v-icon v-if="!$vuetify.breakpoint.mobile" class="ml-n1 mr-2">
                mdi-calendar-outline
              </v-icon>
              <span class="text-truncate">{{ datePeriod }}</span>
            </v-btn>
          </template>
          <v-date-picker
            v-model="dates"
            :allowed-dates="allowedDates"
            color="primary"
            first-day-of-week="1"
            no-title
            range
            @input="setDate()"
          />
        </v-menu>
      </div>
      <div class="pa-2">
        <v-btn-toggle v-model="typeValue" class="btn-group" color="secondary" dense rounded>
          <v-btn v-for="item in typeOptions" :key="item.value" :value="item.value">
            {{ typeLabel(item) }}
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
  </div>
</template>

<script>
import {dateUtcDayOffset} from "@/helpers/dateHelper";
import {mapGetters} from "vuex";

export default {
  name: "StatTopFilterForm",
  props: {
    value: {
      type: Object,
      default: () => ({
        start: undefined,
        end: undefined,
        type: 'income'
      })
    },
  },
  mounted() {
    this.date = 'month';
    this.typeValue = this.search.type || 'income';
  },
  data() {
    return {
      search: {...this.value},
      start: undefined,
      end: undefined,
      date: undefined,
      dates: [],
      menuDate: false,
      typeValue: 'income',
      dateList: [
        {
          label: 'Сегодня',
          value: 'today'
        },
        {
          label: 'Вчера',
          value: 'yesterday'
        },
        {
          label: 'Неделя',
          value: 'week'
        },
        {
          label: 'Месяц',
          value: 'month'
        },
        {
          label: 'Квартал',
          value: 'quarter'
        }
      ],
      typeOptions: [
        {
          label: 'Выручка',
          value: 'income'
        },
        {
          label: 'Сделки',
          value: 'deals'
        },
        {
          label: 'Одобрения',
          value: 'approved'
        },
        {
          label: 'Клиенты',
          value: 'clients'
        },
        {
          label: 'Встречи',
          value: 'meetings'
        }
      ]
    }
  },
  watch: {
    date(value) {
      if (!value) {
        return;
      }
      let start = this.search.start;
      let end = this.search.end;
      switch (value) {
        case 'today':
          start = dateUtcDayOffset();
          end = dateUtcDayOffset();
          break;
        case 'yesterday':
          start = dateUtcDayOffset(-1);
          end = dateUtcDayOffset(-1);
          break;
        case 'week':
          start = dateUtcDayOffset(-7);
          end = dateUtcDayOffset(-1);
          break;
        case 'month':
          start = dateUtcDayOffset(-30);
          end = dateUtcDayOffset(-1);
          break;
        case 'quarter':
          start = dateUtcDayOffset(-90);
          end = dateUtcDayOffset(-1);
          break;
      }
      if (start && end) {
        this.search.start = start;
        this.search.end = end + 86400;
        let offset = new Date().getTimezoneOffset() * 60 * 1000;
        let dateStart = new Date(start * 1000 - offset).toISOString().substring(0, 10);
        let dateEnd = new Date(end * 1000 - offset).toISOString().substring(0, 10)
        this.dates = [dateStart, dateEnd];
        this.update();
      }
    },
    typeValue(value) {
      if (value) {
        this.search.type = value;
        this.update();
      }
    }
  },
  computed: {
    ...mapGetters(['can', 'user']),
    datePeriod() {
      let start = this.search.start ? this.$d(this.search.start * 1000, 'date') : false;
      let end = this.search.end ? this.$d((this.search.end - 86400) * 1000, 'date') : false;
      let dates = [];
      if (start) dates.push(start);
      if (end && end !== start) dates.push(end);
      return dates.join(' - ')
    }
  },
  methods: {
    update() {
      this.$emit('input', {...this.search});
    },
    datePeriodLabel(item) {
      return this.$vuetify.breakpoint.mobile ? item.label.substring(0, 1) : item.label;
    },
    typeLabel(item) {
      // Для мобильных устройств сокращаем названия типов
      return this.$vuetify.breakpoint.mobile ? item.label.substring(0, 3) : item.label;
    },
    allowedDates(value) {
      return new Date().getTime() >= new Date(value).getTime();
    },
    setDate() {
      if (this.dates.length === 2) {
        let [start, end] = this.dates.sort();
        this.search.start = Math.floor(new Date(start).getTime() / 1000)
        this.search.end = Math.floor(new Date(end).getTime() / 1000) + 86400;
        this.date = false;
        this.menuDate = false;
        this.update();
      }
    }
  }
}
</script>

<style scoped>
.filter-btn {
  min-width: 120px;
}
.btn-group {
  white-space: nowrap;
}
</style>