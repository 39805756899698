<template>
  <div>
    <v-progress-linear v-if="model.loading" class="mb-n1" indeterminate/>
    <v-form ref="form" :disabled="model.loading" class="pt-4">
      <v-row>
        <v-col cols="12">
          <v-slider
              v-model="PartitionOrder"
              :hint="hintCount(PartitionOrder)"
              :max="sliderPartitionOrder.max"
              :min="sliderPartitionOrder.min"
              :step="sliderPartitionOrder.step"
              inverse-label
              label="Количество кликов на разделы для активации приоритета"
              persistent-hint
          />
        </v-col>
        <v-col cols="12">
          <v-btn :disabled="model.loading" class="mb-2 mr-2" color="primary" @click="sendForm()">{{ $t('btn.save') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {Form} from "@/models/Form";

export default {
  name:       "PartitionOrderForm",
  components: {},
  props:      {
    show: {
      type:    Boolean,
      default: false
    }
  },
  created() {
    this.initValue();
  },
  mounted() {
    this.loadSetting();
  },
  data: () => ({
    model: Form.create({
      uri: {
        addr:   'setting/set',
        params: {name: 'order'}
      },
      attrs: ['value'],
      on:    {
        error: () => {
          this.$refs.form.validate();
        }
      }
    })
  }),
  watch: {
    show(value) {
      if (value) {
        this.loadSetting();
      }
    }
  },
  computed: {
    PartitionOrder: {
      get() {
        return this.model.attr.value.count;
      },
      set(value) {
        this.model.attr.value.count = value;
      }
    },
    sliderPartitionOrder() {
      return {
        min:  10,
        max:  500,
        step: 10
      }
    }
  },
  methods: {
    sendForm() {
      this.model.send();
    },
    loadSetting() {
      this.initValue();
      this.model.loading = true;
      this.axios.get('setting/get', {params: {name: 'order.count'}})
          .then(response => {
            if (response.data !== null && response.data !== undefined) {
            // Если ответ - просто число
            this.model.attr.value.count = parseInt(response.data, 10);
          }
            this.model.loading = false;
          }).catch(() => {
        this.model.loading = false;
      })
    },
    initValue() {
      this.model.attr = {value: {count: 10}}
    },
    hintCount(value) {
      return value ? value.toString() : '10'
    }
  }
}
</script>

<style scoped>

</style>