<template>
  <v-card class="d-flex flex-column" height="100%" outlined>
    <v-card-title class="py-2 text-subtitle-1">{{ title }}</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="py-0 px-0 flex-grow-1">
      <v-list dense>
        <v-skeleton-loader
          v-if="loading"
          type="list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
        ></v-skeleton-loader>
        <template v-else>
          <v-list-item v-for="(item, index) in items" :key="index" class="py-1">
            <v-list-item-avatar size="30" class="mr-3">
              <v-avatar color="primary" size="30">
                <span class="white--text">{{ index + 1 }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-2">
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="type === 'income'">{{ formatCurrency(item.value) }}</span>
                <span v-else>{{ item.value }} {{ getUnitLabel(type) }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="items.length === 0" class="py-1">
            <v-list-item-content class="text-center">
              <v-list-item-subtitle class="text-subtitle-2 grey--text">
                Нет данных
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "InfoTopCard",
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'income'
    }
  },
  methods: {
    formatCurrency(value) {
      // Форматирование числа как валюты
      return new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value);
    },
    getUnitLabel(type) {
      // Возвращает единицу измерения в зависимости от типа
      const labels = {
        'income': 'руб.',
        'deals': 'сделок',
        'approved': 'одобрений',
        'clients': 'клиентов',
        'meetings': 'встреч'
      };
      return labels[type] || '';
    }
  }
}
</script>

<style scoped>
.v-card__title {
  word-break: normal;
}
</style>