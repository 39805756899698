<template>
  <v-card>
    <v-title title="Настройки"/>
    <v-card-title>
      Настройки
    </v-card-title>
    <v-expansion-panels v-model="value" accordion>

      <v-expansion-panel>
        <v-expansion-panel-header color="#eee">
          Стартовое видео
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <video-start-form :show="value === 0"/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header color="#eee">
          Цитаты дня
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <daily-quotes-form :show="value ===1"/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header color="#eee">
          Настройки безопасности пользователей
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <user-safety-form :show="value === 2"/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header color="#eee">
          Клиенты: запрещенные значения
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <client-deny-templates-form :show="value === 3"/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header color="#eee">
          Порядок разделов
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <partition-order-form :show="value === 4"/>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
  </v-card>
</template>

<script>
import VTitle                  from "../../tags/VTitle";
import {mapGetters}            from "vuex";
import VideoStartForm          from "@/views/setting/VideoStartForm";
import DailyQuotesForm         from "@/views/setting/DailyQuotesForm";
import UserSafetyForm          from "@/views/setting/UserSafetyForm.vue";
import ClientDenyTemplatesForm from "@/views/setting/ClientDenyTemplatesForm.vue";
import PartitionOrderForm      from "@/views/setting/PartitionOrderForm.vue";

export default {
  name:       "Index",
  components: {ClientDenyTemplatesForm, UserSafetyForm, PartitionOrderForm, DailyQuotesForm, VideoStartForm, VTitle},
  data: () => ({
    value: undefined
  }),
  watch:    {},
  computed: {
    ...mapGetters(['can'])
  }
}
</script>

<style scoped>

</style>