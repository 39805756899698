<template>
  <modal v-model="modal" :loading="model.loading" max-width="500px" title="Редактирование профиля">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card-text class="pt-3">
      <v-form ref="form" :disabled="model.loading" @submit.prevent="model.save()">
        <v-row>
          <v-col cols="12">
            <password-input v-model="model.attr.password" :label="$t('user.password')" :rules="model.rule('password')"/>
          </v-col>
          <v-col cols="12">
            <file-input v-model="model.attr.avatar_file" :rules="model.rule('avatar_file')" accept=".png,.jpg,.svg" icon="mdi-file-image" label="Аватар"/>
          </v-col>
          <v-col cols="12">
            <v-select v-model="model.attr.background" :items="backgroundList" :rules="model.rule('background')" label="Фон"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="model.attr.income_goal" :rules="model.rule('income_goal')" label="Цель по квартальному доходу"/>
          </v-col>
        </v-row>
        <h3 class="mt-4 mb-2">Настройки безопасности {{ canSafety ? '' : '(только просмотр)' }}</h3>
        <v-row>
          <v-col cols="12" md="6">
            <v-switch
                v-model="userSafety.twoAuth"
                :disabled="!canSafety"
                :false-value="0"
                :true-value="1"
                label="Двухфакторная авторизация"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-switch
                v-model="userSafety.ipLink"
                :disabled="!canSafety"
                :false-value="0"
                :true-value="1"
                label="Привязка к IP"
            />
          </v-col>
          <v-col cols="12">
            <v-slider
                v-model="userSafety.maxSessions"
                :disabled="!canSafety"
                :hint="hintSessions(userSafety.maxSessions)"
                :max="sliderMaxSessions.max"
                :min="sliderMaxSessions.min"
                :step="sliderMaxSessions.step"
                inverse-label
                label="Максимальное количество сессий"
                persistent-hint
            />
          </v-col>
        </v-row>
        <h3 class="mt-4 mb-2">Приоритет разделов</h3>
        <v-row>
          <v-col cols="12" md="12">
            <!-- Показываем переключатель только если достаточно кликов -->
            <v-switch
              v-if="canUseFrequentSections"
              v-model="userSafety.showFrequentSections"
              :false-value="0"
              :true-value="1"
              label="Показывать сначала часто используемые разделы"
            />
            <!-- Иначе показываем информационное сообщение -->
            <div v-else class="text-body-2 grey--text">
              Функция станет доступной после обучения - обычно это 30 дней активного использования pro CRM SF.
            </div>
          </v-col>
          </v-row>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
      <v-btn :disabled="model.loading" color="primary" @click="model.save()">{{ $t('btn.save') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import Modal         from "@/tags/Modal";
import {User}        from "@/models/User";
import {mapGetters}  from "vuex";
import FileInput     from "@/tags/FileInput";
import PasswordInput from "@/tags/PasswordInput";


export default {
  name:       "UserSelfForm",
  components: {PasswordInput, Modal, FileInput},
  props:      ['id'],
  created() {
    this.initSettingValue();
    this.loadOrderCount();
    this.loadTotalClicks();
  },
  data() {
    return {
      modal:          false,
      frequentSectionsChanged: false,
      orderCount: 0, // Минимальное количество кликов
      totalClicks: 0, // Общее количество кликов
      isDataLoaded: false, // Флаг загрузки данных
      backgroundList: [
        'Весна.jpg',
        'Зима.jpg',
        'Космос.jpg',
        'Лес.jpg',
        'Лето.jpg',
        'МоскваСити.jpg',
        'Небо.jpg',
        'Осень.jpg',
        'Пляж.jpg'
      ],
      model: User.get(this.id, {
        with: ['role', 'setting'],
        on:   {
          load: () => {
            this.initDefaultSettingValue(false);
            this.frequentSectionsChanged = false;
          },
          save:  () => {
            this.close()
            this.$emit('save');

          if (this.frequentSectionsChanged) {
              setTimeout(() => {
                window.location.reload();
              }, 300);
          }  
          },
          saveRole: () => {
            this.close();
            this.$emit('save');
          },
          error: () => {
            this.$refs.form.validate();
          }
        }
      }),
      updated: 0,
    }
  },
  computed: {
    ...mapGetters(['user', 'can']),
    canSafety() {
      return this.can('user.safety');
    },
    canUseFrequentSections() {
      return this.isDataLoaded && this.totalClicks >= this.orderCount;
    },
    sliderMaxSessions() {
      return {
        min:  0,
        max:  10,
        step: 1
      }
    },
    userSafety: {
      get() {
        this.updated;
        return this.model.attr.setting?.safety || this.initSettingValue().safety;
      },
      set(value) {
        this.model.attr.setting.safety = value;
      }
    },
  },
  watch:    {
    'userSafety.showFrequentSections': function() {
      this.frequentSectionsChanged = true;
    },
    modal(value) {
      if (value) {
        this.initSettingValue();
        if (this.id) {
          this.model.load();
        } else {
          this.model.clear();
          this.initDefaultSettingValue();
        }
        this.frequentSectionsChanged = false;
      }
    }
  },
  methods:  {
    close() {
      this.$refs.form.reset();
      this.modal = false;
    },
    loadOrderCount() {
      this.axios.get('setting/get', { params: { name: 'order.count' } })
        .then(response => {
          this.orderCount = parseInt(response.data) || 0;
          this.checkDataLoaded();
        })
        .catch(error => {
          console.error('Ошибка при загрузке order.count:', error);
          this.orderCount = 0;
          this.checkDataLoaded();
        });
    },
    loadTotalClicks() {
      // Получаем ID текущего пользователя
      const userId = this.user?.id;
      
      if (!userId) {
        console.error('ID пользователя не найден');
        this.totalClicks = 0;
        this.checkDataLoaded();
        return;
      }
      
      this.axios.get(`user/view`, {
        params: {
          id: userId,
          expand: 'role,setting'
        }
      })
      .then(response => {
        // Получаем статистику кликов из ответа
        const panelStat = response.data.panel_stat;
        
        if (panelStat) {
          try {
            // Парсим JSON строку
            const statsObj = JSON.parse(panelStat);
            
            // Подсчитываем общую сумму кликов
            this.totalClicks = Object.values(statsObj).reduce((sum, clicks) => sum + clicks, 0);
          } catch (e) {
            console.error('Ошибка при парсинге статистики:', e);
            this.totalClicks = 0;
          }
        } else {
          this.totalClicks = 0;
        }
        
        this.checkDataLoaded();
      })
      .catch(error => {
        console.error('Ошибка при загрузке статистики кликов:', error);
        this.totalClicks = 0;
        this.checkDataLoaded();
      });
    },
    // Метод для проверки загрузки всех данных
    checkDataLoaded() {
      // Устанавливаем флаг, что данные загружены
      this.isDataLoaded = true;
    },
    initSettingValue() {
  if (!this.model.attr.setting) {
    this.model.attr.setting = {
      safety: {
        twoAuth: 0,
        ipLink: 0,
        maxSessions: 0,
        showFrequentSections: 0 // Явно инициализируем поле
      }
    };
  } else if (!this.model.attr.setting.safety) {
    this.model.attr.setting.safety = {
      twoAuth: 0,
      ipLink: 0,
      maxSessions: 0,
      showFrequentSections: 0
    };
  } else if (this.model.attr.setting.safety.showFrequentSections === undefined) {
    // Если поле отсутствует, добавляем его
    this.model.attr.setting.safety.showFrequentSections = 0;
  }
  
  return this.model.attr.setting;
},
initDefaultSettingValue(rewrite = true) {
  this.axios.get('setting/get', {params: {name: 'user.setting.safety'}})
    .then((response) => {
      // Используем деструктуризацию с значениями по умолчанию
      const {
        twoAuth = 0, 
        ipLink = 0, 
        maxSessions = 0, 
        showFrequentSections = 0
      } = response.data || {};
      
      const safety = {
        twoAuth, 
        ipLink, 
        maxSessions, 
        showFrequentSections
      };
      
      if (rewrite) {
        this.userSafety = Object.assign({}, safety);
      } else {
        // Убедимся, что showFrequentSections включен в объединенный объект
        const currentSafety = this.userSafety || {};
        if (currentSafety.showFrequentSections === undefined) {
          currentSafety.showFrequentSections = 0;
        }
        this.userSafety = Object.assign({}, safety, currentSafety);
      }
      
      // Убедимся, что showFrequentSections есть в модели
      if (!this.model.attr.setting.safety.showFrequentSections !== undefined) {
        this.model.attr.setting.safety.showFrequentSections = this.userSafety.showFrequentSections;
      }
      
      this.updated++;
    });
},
    hintSessions(value) {
      return value ? value.toString() : 'Неограниченно'
    }
  }
}
</script>

<style scoped>

</style>