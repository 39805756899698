<template>
  <div>
  <r-card>
  <v-row class="ma-0 pa-2">
  <v-col v-if="can('stat.top.offices')" cols="12" lg="4" sm="6" class="pa-2">
  <info-card
  :loading="loading"
  title="Топ 5 офисов"
  :items="stat?.offices || []"
  :type="type"
  full-number
  />
  </v-col>
  <v-col v-if="can('stat.top.managers')" cols="12" lg="4" sm="6" class="pa-2">
  <info-card
  :loading="loading"
  title="Топ 5 руководителей"
  :items="stat?.managers || []"
  :type="type"
  full-number
  />
  </v-col>
  <v-col v-if="can('stat.top.agents')" cols="12" lg="4" sm="6" class="pa-2">
  <info-card
  :loading="loading"
  title="Топ 5 менеджеров"
  :items="stat?.agents || []"
  :type="type"
  full-number
  />
  </v-col>
  <v-col v-if="can('stat.top.partners')" cols="12" lg="4" sm="6" class="pa-2">
  <info-card
  :loading="loading"
  title="Топ 5 партнеров"
  :items="stat?.partners || []"
  :type="type"
  full-number
  />
  </v-col>
  <v-col v-if="can('stat.top.partner-managers')" cols="12" lg="4" sm="6" class="pa-2">
  <info-card
  :loading="loading"
  title="Топ 5 менеджеров-партнеров"
  :items="stat?.partnerManagers || []"
  :type="type"
  full-number
  />
  </v-col>
  </v-row>
  </r-card>
  </div>
 </template>
 
 <script>
 import RCard from "@/tags/RCard.vue";
 import InfoCard from "@/views/stat/InfoTopCard.vue";
 import { mapGetters } from 'vuex';
 
 export default {
  name: "StatTop",
  components: { InfoCard, RCard },
  props: {
  stat: {
  type: Object,
  default: () => ({})
  },
  loading: {
  type: Boolean,
  default: false
  },
  type: {
  type: String,
  default: 'income'
  }
  },
  computed: {
  ...mapGetters(['can'])
  }
 }
 </script>
 
 <style scoped>
 
 </style>