<template>
  <v-card>
    <v-title title="Статистика"/>
    <v-card-title>Статистика</v-card-title>
    <v-tabs v-model="tabs" :mobile-breakpoint="0" background-color="transparent" class="nowrap" color="primary" fixed-tabs>
      <v-tab v-if="showMainStat">Основное</v-tab>
      <v-tab>Выручка</v-tab>
      <v-tab>Клиенты</v-tab>
      <v-tab>Партнеры</v-tab>
      <v-tab>Воронка</v-tab>
      <v-tab v-if="canViewTop">Топ 5</v-tab>
    </v-tabs>
    <!-- Показываем основной фильтр для всех вкладок, кроме "Топ 5" -->
    <stat-filter-form v-if="tabs !== 5" v-model="form.attr" @input="update()"/>
    <!-- Специальный фильтр для вкладки "Топ 5" -->
    <stat-top-filter-form v-else v-model="topFilterAttr" @input="updateTop()"/>
    <v-progress-linear v-if="form.loading" class="mt-n1" indeterminate/>
    <v-tabs-items v-model="tabs">
      <v-tab-item v-if="showMainStat">
        <stat-main :loading="form.loading" :stat="data.main"/>
      </v-tab-item>
      <v-tab-item>
        <stat-income :loading="form.loading" :stat="data.income"/>
      </v-tab-item>
      <v-tab-item>
        <stat-client :loading="form.loading" :stat="data.client"/>
      </v-tab-item>
      <v-tab-item>
        <stat-deal :loading="form.loading" :stat="data.deal"/>
      </v-tab-item>
      <v-tab-item>
        <stat-funnel :loading="form.loading" :stat="data.funnel"/>
      </v-tab-item>
      <v-tab-item>
        <stat-top :loading="form.loading" :stat="data.top" :type="topFilterAttr.type"/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import VTitle         from "@/tags/VTitle";
import StatFilterForm from "./StatFilterForm";
import {Form}         from "@/models/Form";
import StatMain       from "@/views/stat/StatMain.vue";
import StatIncome     from "@/views/stat/StatIncome.vue";
import StatClient     from "@/views/stat/StatClient.vue";
import StatDeal       from "@/views/stat/StatDeal.vue";
import StatFunnel     from "@/views/stat/StatFunnel.vue";
import StatTop        from "@/views/stat/StatTop.vue";
import StatTopFilterForm from "@/views/stat/StatTopFilterForm.vue";
import {mapGetters}   from "vuex";

export default {
  name:  "Index",
  components: {
    StatIncome, 
    StatMain, 
    StatFilterForm, 
    VTitle, 
    StatClient, 
    StatDeal, 
    StatFunnel,
    StatTop,
    StatTopFilterForm
  },
    data() {
    return {
      tabs: 0,
      data: {},
      topFilterAttr: {
        type: 'income', // По умолчанию фильтр по выручке
        start: null,
        end: null
      },
      form: Form.create({
        attrs: ['start', 'end', 'user_id', 'office_id', 'type'],
        on:    {
          send: (data) => {
            this.data[this.idx] = data;
          }
        }
      }),
      loading: false
    }
  },
  computed: {
    ...mapGetters(['role','can']),
    params() {
      return {
        0: {url: 'stat', idx: 'main'},
        1: {url: 'stat/income', idx: 'income'},
        2: {url: 'stat/client', idx: 'client'},
        3: {url: 'stat/deal', idx: 'deal'},
        4: {url: 'stat/funnel', idx: 'funnel'},
        5: {url: 'stat/top', idx: 'top'}
      }
    },
    url() {
      return this.params[this.tabs].url;
    },
    idx() {
      return this.params[this.tabs].idx;
    },
    showMainStat() {
      return this.role?.name !== 'agent';
    },
    canViewTop() {
      return this.can('stat.top.offices') || 
             this.can('stat.top.managers') || 
             this.can('stat.top.agents') || 
             this.can('stat.top.partners') || 
             this.can('stat.top.partner-managers');
    }
  },
  watch:    {
    tabs() {
      if (this.tabs === 5) {
        if (this.topFilterAttr.start && this.topFilterAttr.end) {
          this.updateTop();
        }
      } else {
          this.update();
      }
    }
  },
  methods:  {
    update() {
      if (this.url) {
        this.form.setUri(this.url).send();
      }
    },
    updateTop() {
      // Проверяем, что URL доступен
      if (this.url) {
        // Обновляем атрибуты формы с параметрами фильтра
        this.form.attr.start = this.topFilterAttr.start;
        this.form.attr.end = this.topFilterAttr.end;
        this.form.attr.type = this.topFilterAttr.type;
        
        // Используем существующий метод setUri и send
        this.form.setUri(this.url).send();
      }
    }
  }
}
</script>

<style scoped>
.nowrap {
  .v-tab {
    white-space: nowrap;
  }
}
</style>