<!-- GoalDialog.vue -->
<template>
    <v-dialog v-model="dialog" max-width="700px">
      <template #activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" color="secondary-lite" class="mr-2">
          <v-icon>mdi-flag</v-icon>
          <span class="d-none d-sm-inline ml-1"></span>
        </v-btn>
      </template>
      
      <v-card>
        <v-overlay :value="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-card-title class="headline d-flex">
          <span>Ваша цель</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <v-card-text>
          <p class="mb-4">
            Уважаемый пользователь {{ user.name }}, для заработка суммы в размере 
            <span class="font-weight-bold">{{ formatMoney(user?.income_goal || 0) }}</span>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon small color="primary" v-bind="attrs" v-on="on" class="ml-1">
                  mdi-help-circle
                </v-icon>
              </template>
              <div class="pa-2">
                <p class="mb-1">Для более точного расчета необходимо за последние 90 дней в pro CRM SF</p>
                <ul class="pl-4">
                  <li>Завести от 150 заявок</li>
                  <li>Провести от 30 встреч</li>
                  <li>Подписать от 15 договоров</li>
                  <li>Подать от 20 заявок</li>
                  <li>Одобрить от 6 заявок</li>
                  <li>Провести минимум 3 сделки</li>
                </ul>
              </div>
            </v-tooltip>
            вам необходимо:
          </p>
          
          <v-simple-table>
            <tbody>
              <tr>
                <td>Обработать лидов</td>
                <td class="text-right">{{ Math.round(goalMetrics.clients) }}</td>
              </tr>
              <tr>
                <td>Провести встреч</td>
                <td class="text-right">{{ Math.round(goalMetrics.meetings) }}</td>
              </tr>
              <tr>
                <td>Подписать договора</td>
                <td class="text-right">{{ Math.round(goalMetrics.signed) }}</td>
              </tr>
              <tr>
                <td>Подать заявки</td>
                <td class="text-right">{{ Math.round(goalMetrics.deals) }}</td>
              </tr>
              <tr>
                <td>Одобрить заявки</td>
                <td class="text-right">{{ Math.round(goalMetrics.approved) }}</td>
              </tr>
              <tr>
                <td>Провести сделок</td>
                <td class="text-right">{{ Math.round(goalMetrics.completed) }}</td>
              </tr>
              <tr>
                <td>Совершить кросс-продажи</td>
                <td class="text-right">{{ Math.round(goalMetrics.crossSales) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        
        <p class="mt-4">
          При этом средний цикл сделки {{ formatDealCycle(statMain?.dealAvgCycle) }}
        </p>
        <p>
          При средней рентабельности бизнеса в размере 35% 
          чистая прибыль составит {{ formatMoney(netProfit) }}
        </p>
        <p>
          Доход менеджера при средней мотивации 25% 
          за сделки под ключ составят {{ formatMoney(managerIncome) }}
        </p>
        
        <h3 class="subtitle-1 mt-4">Источники формирования выручки</h3>
        <v-row>
          <v-col cols="6" sm="3">
            <div class="caption text-uppercase">Банки</div>
            <div>{{ topBanks.join(', ') }}</div>
          </v-col>
          <v-col cols="6" sm="3">
            <div class="caption text-uppercase">Клиенты</div>
            <div>{{ topClientSources.join(', ') }}</div>
          </v-col>
          <v-col cols="6" sm="3">
            <div class="caption text-uppercase">Лиды</div>
            <div>{{ topLeadTypes.join(', ') }}</div>
          </v-col>
          <v-col cols="6" sm="3">
            <div class="caption text-uppercase">Кросс-продажи</div>
            <div>{{ topServiceTypes.join(', ') }}</div>
          </v-col>
        </v-row>
        
        <p class="mt-4">
          Коэффициент увеличения {{ Math.round(growthCoefficient * 10000) }}%
        </p>
        
        <div class="mt-4 text-center">
          <p class="font-italic">
            Желаем Вам успеха в достижении цели! С уважением, команда pro CRM SF
          </p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { formatMoney } from "@/helpers/stringHelpers";

export default {
  name: "GoalDialog",
  data() {
    return {
      dialog: false,
      loading: false,
      statFunnel: null,
      statMain: null,
      statIncome: null,
      statClient: null
    };
  },
  computed: {
    ...mapGetters(['user']),
    
    // Коэффициент увеличения
    growthCoefficient() {
    if (!this.statFunnel || !this.goalMetrics.clients) return 0;
    
    const currentClients = this.statFunnel.clients || 0;
    
    if (currentClients === 0) return 0;
    
    // Коэффициент роста = целевое количество клиентов / текущее количество клиентов
    return this.goalMetrics.clients / currentClients;
  },
    
    // Метрики для цели
    goalMetrics() {
    if (!this.statFunnel || !this.statMain || !this.user?.income_goal) return {};
    
    const currentClients = this.statFunnel.clients || 0;
    const revenue = this.calculateRevenue();
    
    if (currentClients === 0 || revenue === 0) return {
      clients: 0,
      meetings: 0,
      signed: 0,
      deals: 0,
      approved: 0,
      completed: 0,
      crossSales: 0
    };
    
    // Правильный расчет клиентов для достижения цели
    const targetClients = (currentClients * this.user.income_goal) / revenue;
    
    // Коэффициент роста
    const growth = targetClients / currentClients;
    
    return {
      clients: targetClients,
      meetings: (this.statFunnel.meetings || 0) * growth,
      signed: (this.statFunnel.signed || 0) * growth,
      deals: (this.statFunnel.deals || 0) * growth,
      approved: (this.statFunnel.approved || 0) * growth,
      completed: (this.statFunnel.completed || 0) * growth,
      crossSales: (this.statMain?.serviceCount || 0) * growth
    };
  },
    
    // Чистая прибыль (35% от целевого дохода)
    netProfit() {
      return (this.user?.income_goal || 0) * 0.35;
    },
    
    // Доход менеджера (25% от целевого дохода)
    managerIncome() {
      return (this.user?.income_goal || 0) * 0.25;
    },
    
// Топ-3 банка
topBanks() {
  if (!this.statIncome || !this.statIncome.byBank) return ['Нет данных'];
  return this.getTopLabels(this.statIncome.byBank, 3);
},

// Топ-3 источника клиентов
topClientSources() {
  if (!this.statIncome || !this.statIncome.bySource) return ['Нет данных'];
  return this.getTopLabels(this.statIncome.bySource, 3);
},

// Топ-3 типа лидов
topLeadTypes() {
  if (!this.statIncome || !this.statIncome.byType) return ['Нет данных'];
  return this.getTopLabels(this.statIncome.byType, 3);
},

// Топ-3 типа услуг (кросс-продажи)
topServiceTypes() {
  if (!this.statIncome || !this.statIncome.byService) return ['Нет данных'];
  return this.getTopLabels(this.statIncome.byService, 3);
}
  },
  methods: {
    formatMoney,

    formatDealCycle(seconds) {
        if (!seconds) return '0 дней';
        
        // Преобразуем секунды в дни и округляем до целого
        const days = Math.round(seconds / 86400);
        
        // Склонение слова "день" в зависимости от числа
        let dayWord = 'дней';
        if (days % 10 === 1 && days % 100 !== 11) {
            dayWord = 'день';
        } else if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) {
            dayWord = 'дня';
        }
        
        return `${days} ${dayWord}`;
    },
    
    // Получаем топ N меток из данных графика
    getTopLabels(data, count = 3) {
        if (!data || !Array.isArray(data) || data.length === 0) {
            return ['Нет данных'];
        }
        
        // Сортируем элементы по значению value (по убыванию) и берем первые N
        const topItems = [...data]
            .sort((a, b) => (b.value || 0) - (a.value || 0))
            .slice(0, count);
        
        // Возвращаем метки (названия)
        return topItems.map(item => item.name || 'Без названия');
    },
    // Расчет общей выручки
    calculateRevenue() {
      if (!this.statMain) return 0;
      
      const dealRevenue = (this.statMain.dealAvgPrice || 0) * (this.statFunnel?.completed || 0);
      const crossSalesRevenue = (this.statMain.serviceAvgPrice || 0) * (this.statMain.serviceCount || 0);
      
      return dealRevenue + crossSalesRevenue;
    },
    
    // Загрузка данных статистики
    async fetchStatData() {
      this.loading = true;
      
      try {
        // Получаем текущую дату в UNIX-формате (миллисекунды)
        const endTime = Math.floor(Date.now() / 1000);
        const startTime = 0; // Начало времен для получения всей статистики
        
        // Параметры запроса
        const params = {
          start: startTime,
          end: endTime,
          user_id: this.user?.id,
          office_id: this.user?.office_id
        };
        
        // Загружаем данные воронки
        const funnelResponse = await this.axios.post('/stat/funnel', { params });
        this.statFunnel = funnelResponse.data;
        
        // Загружаем основную статистику
        const mainResponse = await this.axios.post('/stat', { params });
        this.statMain = mainResponse.data;
        
        // Загружаем статистику доходов
        const incomeResponse = await this.axios.post('/stat/income', { params });
        this.statIncome = incomeResponse.data;
        
        // Загружаем статистику клиентов
        const clientResponse = await this.axios.post('/stat/client', { params });
        this.statClient = clientResponse.data;
        
      } catch (error) {
        console.error('Failed to load statistics data', error);
        // Можно добавить уведомление об ошибке
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fetchStatData();
      }
    }
  }
};
</script>

<style scoped>
.income-stat-item--title {
  font-size: 12px;
  line-height: 1.2;
}

.income-stat-item--content {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}
</style>